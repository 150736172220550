import { triggerAlertDialog } from '@polydice/ui/dist/Dialog';
import { captureException } from '@sentry/browser';
import { getAuthToken } from 'reactjs/components/utility';

const chargeButtonSelector = '.js-order-go-charge';
const chargeButtons = document.querySelectorAll(chargeButtonSelector);

const beforeUnloadCallback = (event) => {
  const unloadMessage = '付款程序進行中，請稍候';
  event.preventDefault();
  event.returnValue = unloadMessage; // eslint-disable-line no-param-reassign
  return unloadMessage;
};

const setAllChargeButtonsDisable = (disabled) => {
  chargeButtons.forEach((chargeButton) => {
    if (disabled) {
      chargeButton.textContent = '請稍候...';
      chargeButton.setAttribute('disabled', true);
      chargeButton.classList.add('payment-status_phase__link--disable');
    } else {
      chargeButton.textContent = '選擇付款方式';
      chargeButton.setAttribute('disabled', false);
      chargeButton.classList.remove('payment-status_phase__link--disable');
    }
  });
};

const beforeCharge = () => {
  setAllChargeButtonsDisable(true);
  window.addEventListener('beforeUnload', beforeUnloadCallback);
};

const afterCharge = () => {
  setAllChargeButtonsDisable(false);
  window.removeEventListener('beforeunload', beforeUnloadCallback);
};

const charge = (orderId) =>
  fetch(`/orders/${orderId}/charge`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-CSRF-Token': getAuthToken()
    }
  });

chargeButtons.forEach((chargeButton) => {
  chargeButton.addEventListener('click', async (event) => {
    beforeCharge();

    try {
      const response = await charge(event.target.dataset.orderId);

      if (response.ok) {
        afterCharge();
        const { charge_url } = await response.json();
        window.location.assign(charge_url);
      } else {
        const { errors } = await response.json();
        triggerAlertDialog({
          children: errors[0],
          positiveButtonText: '確定'
        });
      }
    } catch (error) {
      captureException(error);
    }
  });
});

export default charge;
