import 'stylesheets/application-webpack.scss';

import '../../assets/javascripts/adUnifiedId';
import '../../assets/javascripts/components/sentry';
import '../../assets/javascripts/components/facebook';
import '../../assets/javascripts/components/header-categories';

import ujs from '@rails/ujs';
ujs.start();

import '@polydice/icook-navbar';
import '../../assets/javascripts/components/categoriesNav';
import '../../assets/javascripts/components/webviewTrack';

import '../../assets/javascripts/components/charge';
import '../../assets/javascripts/reactjs/app';
