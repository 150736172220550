import { renderCustomerChat } from './components/CustomerChat';
renderCustomerChat();

import ReactOnRails from 'react-on-rails';
import { lazyHydrate, noHydrate } from './utils/hydrate';

ReactOnRails.register({
  BrowserDetector: noHydrate,
  Message: lazyHydrate(() =>
    import(/* webpackChunkName: "Message" */ './components/Message')
  ),
  CouponBanner: lazyHydrate(() =>
    import(/* webpackChunkName: "CouponBanner" */ './components/CouponBanner')
  ),
  CategoryBanner: lazyHydrate(() =>
    import(
      /* webpackChunkName: "CategoryBanner" */ './components/Category/CategoryBanner'
    )
  ),
  CategoryProducts: lazyHydrate(() =>
    import(
      /* webpackChunkName: "CategoryProducts" */ './components/Category/CategoryProducts'
    )
  ),
  OrderInfo: lazyHydrate(() =>
    import(/* webpackChunkName: "OrderInfo" */ './components/Order/OrderInfo')
  ),
  BannerSlider: lazyHydrate(() =>
    import(
      /* webpackChunkName: "BannerSlider" */ './components/Home/BannerSlider'
    )
  ),
  BrowsingHistorySlider: lazyHydrate(() =>
    import(
      /* webpackChunkName: "BrowsingHistorySlider" */ './components/Home/BrowsingHistorySlider'
    )
  ),
  Cool3CBrowsingHistorySlider: lazyHydrate(() =>
    import(
      /* webpackChunkName: "Cool3CBrowsingHistorySlider" */ './components/Home/Cool3CBrowsingHistorySlider'
    )
  ),
  TopSellersSlider: lazyHydrate(() =>
    import(
      /* webpackChunkName: "TopSellersSlider" */ './components/Home/TopSellersSlider'
    )
  ),
  JustBoughtSlider: lazyHydrate(() =>
    import(
      /* webpackChunkName: "JustBoughtSlider" */ './components/Home/JustBoughtSlider'
    )
  ),
  MightLikeSlider: lazyHydrate(() =>
    import(
      /* webpackChunkName: "MightLikeSlider" */ './components/Home/MightLikeSlider'
    )
  )
});
